import {
  Box,
  Button,
  Card,
  Text,
  Page,
  WixDesignSystemProvider,
  Divider,
  FormField,
  ToggleSwitch,
  CircularProgressBar,
  Layout,
  Cell,
  SegmentedToggle,
  ColorInput,
  Input,
  Notification,
  SelectableAccordion,
  IconButton,
  Tooltip,
  TextButton,
  SectionHelper,
  PulseAnimation,
  CardGalleryItem,
  AutoComplete,
  listItemSelectBuilder,
  CustomModalLayout,
  Modal,
  InputArea,
  ImageViewer,
  TimeInput,
  AnnouncementModalLayout,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { TestimonialList } from '@certifiedcode/components';
import React, { useEffect, useRef } from "react";
// import HeroImage from "./whatsapp.png";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import { useTranslation } from "react-i18next";
const countryCodes = require("country-codes-list");
const { phone } = require("phone");

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function extractImageUrl(avatarSrc: string): string {
  // If it's already a plain URL, return it
  if (avatarSrc.startsWith("http") && !avatarSrc.includes("<")) {
    return avatarSrc;
  }

  // Try to extract URL from img tag
  const imgMatch = avatarSrc.match(/<img[^>]+src="([^"]+)"[^>]*>/);
  if (imgMatch) {
    return imgMatch[1];
  }

  // Try to extract URL from other HTML tags
  const srcMatch = avatarSrc.match(/src="([^"]+)"/);
  if (srcMatch) {
    return srcMatch[1];
  }

  return avatarSrc; // Return as-is if no patterns match
}

function App() {
  const { t } = useTranslation();
  const APPS: any = {
    "463fa61d-e677-4847-8749-b708786d1176": {
      icon: "https://static.wixstatic.com/media/bec40d_73763839349548eea3489210b8ecf59b~mv2.png",
      page: {
        name: "WhatsApp",
      },
      server: {
        name: "whatsapp",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-whatsapp"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#10c379" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#10c379" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
                mobile:
                  "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "4a40e74c-42a0-4a89-8a00-71eca92ea200": {
      icon: "https://static.wixstatic.com/media/bec40d_6b96abaf43a64b9abfe48989dad9ed94~mv2.png",
      page: {
        name: "Telegram",
      },
      server: {
        name: "telegram",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-telegram"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#18A3E6" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#18A3E6" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "https://t.me/yourusername" /* Writing is required */,
                mobile:
                  "https://t.me/yourusername" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "c4aed3c4-7d9c-4a20-90a4-61515043f9f8": {
      icon: "https://static.wixstatic.com/media/bec40d_20a9f3d908c44fd8a3c96bb95abeef82~mv2.png",
      page: {
        name: "Messenger",
      },
      server: {
        name: "messenger",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-facebook-messenger"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#0084ff" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#0084ff" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "http://m.me/PAGE-NAME" /* Writing is required */,
                mobile:
                  "http://m.me/PAGE-NAME" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "6986524e-cf47-4bf4-b01e-028db9023c82": {
      icon: "https://static.wixstatic.com/media/bec40d_7726144faccb4f39a6e6f788fb21eaae~mv2.png",
      page: {
        name: "Instagram",
      },
      server: {
        name: "instagram",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-instagram"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#C837AB" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#C837AB" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "https://ig.me/m/wix" /* Writing is required */,
                mobile:
                  "https://ig.me/m/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "226c485b-7f5d-41a8-b75c-ac33b079e821": {
      icon: "https://static.wixstatic.com/media/bec40d_ec1838f222c347ad89e5a3391b0bc6d9~mv2.png",
      page: {
        name: "Facebook",
      },
      server: {
        name: "facebook",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-facebook"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#0062E0" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#0062E0" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://www.facebook.com/wix/" /* Writing is required */,
                mobile:
                  "https://www.facebook.com/wix/" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "938f2e51-0e4e-4ba2-a876-6cfb15a74828": {
      icon: "https://static.wixstatic.com/media/bec40d_912b9eba04e847eb98c67e8cb180d434~mv2.png",
      page: {
        name: "TikTok",
      },
      server: {
        name: "tiktok",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fab fa-tiktok"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#FF004F" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://www.tiktok.com/@wix" /* Writing is required */,
                mobile:
                  "https://www.tiktok.com/@wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "a9bbd5c7-dadf-47fa-b035-6f1d754ce440": {
      icon: "https://static.wixstatic.com/media/bec40d_04c5539df8074abe9848dcc16b4a603e~mv2.png",
      page: {
        name: "X (Twitter)",
      },
      server: {
        name: "twitter",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-x-twitter"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#000000" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "https://www.x.com/wix" /* Writing is required */,
                mobile:
                  "https://www.x.com/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "55028134-4641-4561-ab74-783641691cdf": {
      icon: "https://static.wixstatic.com/media/bec40d_c8a36a4d0067470aaa9fbcc6996a56c3~mv2.png",
      page: {
        name: "Discord",
      },
      server: {
        name: "discord",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-discord"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#5765f2" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://discord.com/invite/devs-on-wix" /* Writing is required */,
                mobile:
                  "https://discord.com/invite/devs-on-wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "e18c2062-1f01-41e0-ad84-fd4883cfd746": {
      icon: "https://static.wixstatic.com/media/bec40d_bebca3e3980e4f9b90776e09a9953b17~mv2.png",
      page: {
        name: "LinkedIn",
      },
      server: {
        name: "linkedin",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-linkedin"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#0B66C2" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://www.linkedin.com/company/wix-com" /* Writing is required */,
                mobile:
                  "https://www.linkedin.com/company/wix-com" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "2de233f5-f82a-44b0-ad7f-f41651e870f1": {
      icon: "https://static.wixstatic.com/media/bec40d_0ef46e51622e43c48a2e588d484b4219~mv2.png",
      page: {
        name: "Snapchat",
      },
      server: {
        name: "snapchat",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-snapchat" style="color: black"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#fffc04" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://www.snapchat.com/add/[USERNAME HERE]" /* Writing is required */,
                mobile:
                  "https://www.snapchat.com/add/[USERNAME HERE]" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "764f46b2-7566-4133-9fcd-092b28fd970d": {
      icon: "https://static.wixstatic.com/media/bec40d_080a8e2720b44ddc8b5f9a778527c050~mv2.png",
      page: {
        name: "SMS",
      },
      server: {
        name: "sms-native",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-solid fa-comment-sms"></i>' /* Image, Icon or SVG */,
          backgroundColor: "green" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "sms://13475086396" /* Writing is required */,
                mobile:
                  "sms://13475086396" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "fd63c627-95c5-4c21-b48f-540dc2c31bc8": {
      icon: "https://static.wixstatic.com/media/bec40d_97f7e7dd866d43c38974a4719e5385cc~mv2.png",
      page: {
        name: "WeChat (Weixin)",
      },
      server: {
        name: "wechat",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-weixin"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#09B83E" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "weixin://dl/chat?YOUR_WECHAT_ID" /* Writing is required */,
                mobile:
                  "weixin://dl/chat?YOUR_WECHAT_ID" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "1e1d660a-d0e0-4259-b451-02017ba20f7a": {
      icon: "https://static.wixstatic.com/media/bec40d_ccdfd85ede8f404eb805b26b7a6b9029~mv2.png",
      page: {
        name: "Skype",
      },
      server: {
        name: "skype",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-skype"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#00aff0" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "skype:YOUR_SKYPE_USERNAME?chat" /* Writing is required */,
                mobile:
                  "skype:YOUR_SKYPE_USERNAME?chat" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "97080b01-a28f-451f-9a56-99276ce155cd": {
      icon: "https://static.wixstatic.com/media/bec40d_869c743e427646f9a891d8c3e1977e3e~mv2.png",
      page: {
        name: "Vkontakte",
      },
      server: {
        name: "vkontakte",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-vk"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#4C75A3" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "https://www.x.com/wix" /* Writing is required */,
                mobile:
                  "https://www.x.com/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "6df592f2-25dd-46eb-aff3-2941fe16125b": {
      icon: "https://static.wixstatic.com/media/bec40d_f21ec5a0310f49888f6eccfe1a04c1a2~mv2.png",
      page: {
        name: "Slack",
      },
      server: {
        name: "slack",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-slack"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#49134a" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "PASTE CHAT/GROUP INVITE URL HERE" /* Writing is required */,
                mobile:
                  "PASTE CHAT/GROUP INVITE URL HERE" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "d6efa836-c8d5-4001-9598-8ca79f6abf27": {
      isPrivate: true,
      icon: "https://static.wixstatic.com/shapes/8db34d_961f48a684c34e33914a308812f685e1.svg",
      page: {
        name: "Email",
      },
      server: {
        name: "email",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-solid fa-envelope"></i>' /* Image, Icon or SVG */,
          backgroundColor: "red" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "mailto:example@wix.cocm" /* Writing is required */,
                mobile:
                  "mailto:example@wix.cocm" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "08c29bbe-527f-48ed-87bc-1fe8ffb43cca": {
      icon: "https://static.wixstatic.com/media/bec40d_2742846a2c324531bf687a95893de3da~mv2.png",
      page: {
        name: "Viber",
      },
      server: {
        name: "viber",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-viber"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#7360f2" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "viber://pa?chatURI=[public account URI]&text=[message text]" /* Writing is required */,
                mobile:
                  "viber://pa?chatURI=[public account URI]&text=[message text]" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "797cdc28-6218-4a81-b1f0-f5ff20e311d2": {
      icon: "https://static.wixstatic.com/media/bec40d_2ade5e71035642e7bf6254be2ef574c7~mv2.png",
      page: {
        name: "Phone",
      },
      server: {
        name: "phone",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-solid fa-phone"></i>' /* Image, Icon or SVG */,
          backgroundColor: "green" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop: "tel:+13476086396" /* Writing is required */,
                mobile:
                  "tel:+13476086396" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
    "3af018d7-2c0e-4a5f-876e-ffc0f236157e": {
      icon: "https://static.wixstatic.com/media/bec40d_248ec8a7ad9a44e0b8413a437cdce8c8~mv2.png",
      page: {
        name: "LINE",
      },
      server: {
        name: "line",
      },
      default: {
        /* Button Settings */
        button: {
          position:
            "right" /* left, right or false. "position:false" does not pin to the left or right */,
          style: 1 /* Button style. Number between 1 and 7 */,
          src: '<i class="fa-brands fa-line"></i>' /* Image, Icon or SVG */,
          backgroundColor: "#06C755" /* Html color code */,
          effect: 1 /* Button effect. Number between 1 and 7 */,
          notificationNumber:
            "1" /* Custom text or false. To remove, (notificationNumber:false) */,
          speechBubble:
            t("chat-button-speech") /* To remove, (speechBubble:false) */,
          pulseEffect: true /* To remove, (pulseEffect:false) */,
          text: {
            /* For Button style larger than 1 */
            title: t("chat-button-title") /* Writing is required */,
            description: t(
              "chat-button-description"
            ) /* To remove, (description:false) */,
            online: t("chat-button-online") /* To remove, (online:false) */,
            offline: t("chat-button-offline") /* To remove, (offline:false) */,
          },
        },

        /* Popup Settings */
        popup: {
          automaticOpen:
            false /* true or false (Open popup automatically when the page is loaded) */,
          outsideClickClosePopup:
            true /* true or false (Clicking anywhere on the page will close the popup) */,
          effect: 1 /* Popup opening effect. Number between 1 and 15 */,
          header: {
            backgroundColor: "#FF004F" /* Html color code */,
            title: t("chat-button-title") /* Writing is required */,
            description:
              t("chat-description") /* To remove, (description:false) */,
          },

          /* Representative Settings */
          persons: [
            /* Copy for more representatives [::Start Copy::] */
            {
              avatar: {
                src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
                backgroundColor: "#ffffff" /* Html color code */,
                onlineCircle:
                  true /* Avatar online circle. To remove, (onlineCircle:false) */,
              },
              text: {
                title: t("person-title") /* Writing is required */,
                description:
                  t("person-description") /* To remove, (description:false) */,
                online: t("person-online") /* To remove, (online:false) */,
                offline: t("person-offline") /* To remove, (offline:false) */,
                button: "Start Chat"
              },
              link: {
                desktop:
                  "https://line.me/R/ti/p/YOUR_LINE_ID" /* Writing is required */,
                mobile:
                  "https://line.me/R/ti/p/YOUR_LINE_ID" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
              },
              onlineDay: {
                /* Change the day you are offline like this. (sunday:false) */
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
              },
            },
            /* [::End Copy::] */
          ],
        },

        /* Other Settings */
        sound:
          true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
        changeBrowserTitle:
          t(
            "new-message"
          ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
        cookie:
          false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
      },
    },
  };

  const SAMPLE_PERSON: any =
    /* Copy for more representatives [::Start Copy::] */
    {
      avatar: {
        src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
        backgroundColor: "#ffffff" /* Html color code */,
        onlineCircle:
          true /* Avatar online circle. To remove, (onlineCircle:false) */,
      },
      text: {
        title: t("person-title") /* Writing is required */,
        description:
          t("person-description") /* To remove, (description:false) */,
        online: t("person-online") /* To remove, (online:false) */,
        offline: t("person-offline") /* To remove, (offline:false) */,
        button: "Start Chat" },
      link: {
        desktop: "" /* Writing is required */,
        mobile:
          "" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
      },
      onlineDay: {
        /* Change the day you are offline like this. (sunday:false) */
        sunday: "00:00-23:59",
        monday: "00:00-23:59",
        tuesday: "00:00-23:59",
        wednesday: "00:00-23:59",
        thursday: "00:00-23:59",
        friday: "00:00-23:59",
        saturday: "00:00-23:59",
      },
    };
  ReactTagManager.init(tagManagerArgs);
  const personList = useRef(null);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isShowSaved, setIsShowSaved] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] = React.useState(false);
  const [canUsePremium, setCanUsePremium] = React.useState(false);
  const [appId, setAppId] = React.useState(
    "463fa61d-e677-4847-8749-b708786d1176"
  );

  const [isShowWaHelper, setIsShowWaHelper] = React.useState(false);
  const [isShowTelegramHelper, setIsShowTelegramHelper] = React.useState(false);
  const [isShowMessengerHelper, setIsShowMessengerHelper] =
    React.useState(false);
  const [isShowViberHelper, setIsShowViberHelper] = React.useState(false);
  const [selectedPersonIndex, setSelectedPersonIndex] = React.useState(0);

  const [showPostPublish, setShowPostPublish] = React.useState(false);

  const [showPulseOnLink, setShowPulseOnLink] = React.useState(false);

  const [client, setClient] = React.useState(null as any);

  const [config, setConfig] = React.useState({
    /* Button Settings */
    button: {
      position:
        "right" /* left, right or false. "position:false" does not pin to the left or right */,
      style: 1 /* Button style. Number between 1 and 7 */,
      src: '<i class="fab fa-whatsapp"></i>' /* Image, Icon or SVG */,
      backgroundColor: "#10c379" /* Html color code */,
      effect: 1 /* Button effect. Number between 1 and 7 */,
      notificationNumber:
        "1" /* Custom text or false. To remove, (notificationNumber:false) */,
      speechBubble:
        t("chat-button-speech") /* To remove, (speechBubble:false) */,
      pulseEffect: true /* To remove, (pulseEffect:false) */,
      text: {
        /* For Button style larger than 1 */
        title: t("chat-button-title") /* Writing is required */,
        description: t(
          "chat-button-description"
        ) /* To remove, (description:false) */,
        online: t("chat-button-online") /* To remove, (online:false) */,
        offline: t("chat-button-offline") /* To remove, (offline:false) */,
        button: "Start Chat"
      },
    },

    /* Popup Settings */
    popup: {
      automaticOpen:
        false /* true or false (Open popup automatically when the page is loaded) */,
      outsideClickClosePopup:
        true /* true or false (Clicking anywhere on the page will close the popup) */,
      effect: 1 /* Popup opening effect. Number between 1 and 15 */,
      header: {
        backgroundColor: "#10c379" /* Html color code */,
        title: t("chat-button-title") /* Writing is required */,
        description: t("chat-description") /* To remove, (description:false) */,
      },

      /* Representative Settings */
      persons: [
        /* Copy for more representatives [::Start Copy::] */
        {
          avatar: {
            src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
            backgroundColor: "#ffffff" /* Html color code */,
            onlineCircle:
              true /* Avatar online circle. To remove, (onlineCircle:false) */,
          },
          text: {
            title: t("person-title") /* Writing is required */,
            description:
              t("person-description") /* To remove, (description:false) */,
            online: t("person-online") /* To remove, (online:false) */,
            offline: t("person-offline") /* To remove, (offline:false) */,
            button: "Start Chat"
          },
          link: {
            desktop:
              "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
            mobile:
              "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
          },
          onlineDay: {
            /* Change the day you are offline like this. (sunday:false) */
            sunday: "00:00-23:59",
            monday: "00:00-23:59",
            tuesday: "00:00-23:59",
            wednesday: "00:00-23:59",
            thursday: "00:00-23:59",
            friday: "00:00-23:59",
            saturday: "00:00-23:59",
          },
        },
        /* [::End Copy::] */
      ],
    },

    /* Other Settings */
    sound:
      true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
    changeBrowserTitle:
      t(
        "new-message"
      ) /* Custom text or false. To remove, (changeBrowserTitle:false) */,
    cookie:
      false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,

    default: true,
  } as any);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    try {
      setClient(
        createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isUpgraded) {
      setCanUsePremium(true);
    } else {
      if (trialRemainingDate > 0 && !APPS[appId]?.isPrivate) {
        setCanUsePremium(true);
      } else {
        setCanUsePremium(false);
      }
    }
  }, [isUpgraded, trialRemainingDate]);

  useEffect(() => {
    // find appId from url
    const appId = new URLSearchParams(window.location.search).get("appId");
    if (appId) {
      setAppId(appId);
    }
  }, []);

  useEffect(() => {
    if (appId) {
      setConfig(APPS[appId]?.default);
      setIntercomIdentity();
      setInstance();
      getSettings();
    }
  }, [appId]);

  useEffect(() => {
    if (instanceData) {
      var wixManagedTrialEndDate =
        instanceData?.instance?.billing?.freeTrialInfo?.endDate; // this gives a date object, which needs to convert to remaining days
      if (wixManagedTrialEndDate) {
        var today = new Date();
        var diff = new Date(wixManagedTrialEndDate).getTime() - today.getTime();
        var days = diff / 1000 / 60 / 60 / 24;
        setTrialRemainingDate(Math.round(days));
      } else {
        getTrial();
      }
    }
  }, [instanceData]);

  useEffect(() => {
    console.log(config);
    if (config?.button?.style && !token) {
      try {
        // remove existing chat element
        window.$("#whatsapp-preview").remove();
        // create new chat element to body
        window.$("body").append('<div id="whatsapp-preview"></div>');
        window.$("#whatsapp-preview").czmChatSupport(config);
      } catch (error) {
        console.error(error);
      }
    }
  }, [config]);

  const BASE_URL = `https://certifiedcode.wixsite.com/${APPS[appId]?.server.name}/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
        if (data?.config) {
          setConfig(data.config);
        } else {
          // Start the Intercom tour if no settings exist
          window.Intercom("startTour", 589978);
        }
      })
      .catch(() => {});
  }

  function setSettings() {
    setIsShowSaved(false);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
        config,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        !isUpgraded && setShowPostPublish(true);
        console.log(data);
        setIsEnabled(data.isEnabled);
        console.log("set settings done");
        setIsShowSaved(true);
        setTimeout(() => {
          if (isShowSaved) {
            setIsShowSaved(false);
          }
        }, 3000);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      })
      .catch(() => {});
  }

  const scrollToComponent = () => {
    // @ts-ignore
    personList.current.scrollIntoView();
    setShowPulseOnLink(true);
    setTimeout(() => {
      setShowPulseOnLink(false);
    }, 3000);
  };

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${
      new URLSearchParams(window.location.search).get("appId") ||
      "463fa61d-e677-4847-8749-b708786d1176"
    }&redirectUrl=https://certifiedcode.wixsite.com/${
      APPS[appId]?.server.name
    }/_functions/@certifiedcode/base-backend/auth`;
    return <></>;
  }

  const PremiumHint = () => (
    <Tooltip content={t("tooltip-upgrade-title")}>
      <IconButton
        size="tiny"
        skin="premium"
        as={"a"}
        href={`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
          (instanceData as any)?.instance?.instanceId
        }`}
        target="_blank"
      >
        <Icons.PremiumFilledSmall />
      </IconButton>
    </Tooltip>
  );

  const WaHelper = ({ onGenerateLink }: any) => {
    const [number, setNumber] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("+1");
    const [defaultMessage, setDefaultMessage] = React.useState("Hello");

    const list = countryCodes.customList(
      "countryCallingCode",
      "{countryNameEn}"
    );

    const options = Object.keys(
      countryCodes.customList("countryCallingCode", "{countryNameEn}")
    ).map((k: any, i: number) =>
      listItemSelectBuilder({ id: `+${k}`, title: list[k], suffix: `+${k}` })
    );
    const onChange = (e: any) => {
      const { value } = e.target;
      setNumber(value);
    };

    const renderAutoComplete = () => (
      <AutoComplete
        popoverProps={{ placement: "bottom-start" }}
        value={countryCode}
        options={options}
        onSelect={(option: any) => {
          console.log(option);
          setCountryCode(option.id);
        }}
      />
    );

    const generateAndSendLink = () => {
      const fullNumber = countryCode.replace("+", "") + number;
      const encodedMessage = encodeURIComponent(defaultMessage);
      onGenerateLink({
        desktop: `https://web.whatsapp.com/send?phone=${fullNumber}&text=${encodedMessage}`,
        mobile: `https://wa.me/${fullNumber}/?text=${encodedMessage}`,
      });
    };

    return (
      <CustomModalLayout
        primaryButtonText={t("primaryButtonText")}
        primaryButtonOnClick={() => {
          generateAndSendLink();
          setIsShowWaHelper(false);
        }}
        secondaryButtonText={t("secondaryButtonText")}
        secondaryButtonOnClick={() => setIsShowWaHelper(false)}
        onCloseButtonClick={() => setIsShowWaHelper(false)}
        title={t("generate-whatsapp-title")}
        overflowY="visible"
        height="350px"
        content={
          <FormField
            label={t("label-phone-number")}
            status={
              phone(countryCode + number)?.isValid ? undefined : "warning"
            }
            statusMessage={
              phone(countryCode + number)?.isValid
                ? ""
                : t("invalid-status-msg")
            }
          >
            <Layout gap={6}>
              <Cell span={2}>{renderAutoComplete()}</Cell>
              <Cell span={10}>
                <Input
                  type="number"
                  placeholder="12345678"
                  onChange={onChange}
                  value={number}
                />
              </Cell>
              <Cell span={12}>
                <FormField label={t("label-default")}>
                  <InputArea
                    value={defaultMessage}
                    onChange={(e) => {
                      setDefaultMessage(e.target.value);
                    }}
                  />
                </FormField>
              </Cell>
            </Layout>
          </FormField>
        }
      />
    );
  };

  const TelegramHelper = ({ onGenerateLink }: any) => {
    const { t } = useTranslation();
    const [username, setUsername] = React.useState("");
    const [defaultMessage, setDefaultMessage] = React.useState("Hello");

    const generateAndSendLink = () => {
      const encodedMessage = encodeURIComponent(defaultMessage);
      onGenerateLink({
        desktop: `https://t.me/${username}?text=${encodedMessage}`,
        mobile: `https://t.me/${username}?text=${encodedMessage}`,
      });
    };

    return (
      <CustomModalLayout
        primaryButtonText={t("primaryButtonText")}
        primaryButtonOnClick={() => {
          generateAndSendLink();
          setIsShowTelegramHelper(false);
        }}
        secondaryButtonText={t("secondaryButtonText")}
        secondaryButtonOnClick={() => setIsShowTelegramHelper(false)}
        onCloseButtonClick={() => setIsShowTelegramHelper(false)}
        title={t("generate-telegram-title")}
        overflowY="visible"
        height="350px"
        content={
          <Layout gap={6}>
            <Cell span={12}>
              <FormField label={t("label-username")}>
                <Input
                  placeholder="username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  prefix={<Text>@</Text>}
                />
              </FormField>
            </Cell>
            <Cell span={12}>
              <FormField label={t("label-default")}>
                <InputArea
                  value={defaultMessage}
                  onChange={(e) => {
                    setDefaultMessage(e.target.value);
                  }}
                />
              </FormField>
            </Cell>
          </Layout>
        }
      />
    );
  };

  const MessengerHelper = ({ onGenerateLink }: any) => {
    const { t } = useTranslation();
    const [pageId, setPageId] = React.useState("");
    const [defaultMessage, setDefaultMessage] = React.useState("Hello");

    const generateAndSendLink = () => {
      const encodedMessage = encodeURIComponent(defaultMessage);
      onGenerateLink({
        desktop: `https://m.me/${pageId}?text=${encodedMessage}`,
        mobile: `https://m.me/${pageId}?text=${encodedMessage}`,
      });
    };

    return (
      <CustomModalLayout
        primaryButtonText={t("primaryButtonText")}
        primaryButtonOnClick={() => {
          generateAndSendLink();
          setIsShowMessengerHelper(false);
        }}
        secondaryButtonText={t("secondaryButtonText")}
        secondaryButtonOnClick={() => setIsShowMessengerHelper(false)}
        onCloseButtonClick={() => setIsShowMessengerHelper(false)}
        title={t("generate-messenger-title")}
        overflowY="visible"
        height="350px"
        content={
          <Layout gap={6}>
            <Cell span={12}>
              <FormField label={t("label-page-id")}>
                <Input
                  placeholder="Facebook Page ID or username"
                  onChange={(e) => setPageId(e.target.value)}
                  value={pageId}
                />
              </FormField>
            </Cell>
            <Cell span={12}>
              <FormField label={t("label-default")}>
                <InputArea
                  value={defaultMessage}
                  onChange={(e) => {
                    setDefaultMessage(e.target.value);
                  }}
                />
              </FormField>
            </Cell>
          </Layout>
        }
      />
    );
  };

  const ViberHelper = ({ onGenerateLink }: any) => {
    const { t } = useTranslation();
    const [number, setNumber] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("+1");
    const [defaultMessage, setDefaultMessage] = React.useState("Hello");

    const list = countryCodes.customList(
      "countryCallingCode",
      "{countryNameEn}"
    );

    const options = Object.keys(list).map((k: any) =>
      listItemSelectBuilder({ id: `+${k}`, title: list[k], suffix: `+${k}` })
    );

    const generateAndSendLink = () => {
      const fullNumber = countryCode.replace("+", "") + number;
      const encodedMessage = encodeURIComponent(defaultMessage);
      onGenerateLink({
        desktop: `viber://chat?number=${fullNumber}&text=${encodedMessage}`,
        mobile: `viber://chat?number=${fullNumber}&text=${encodedMessage}`,
      });
    };

    return (
      <CustomModalLayout
        primaryButtonText={t("primaryButtonText")}
        primaryButtonOnClick={() => {
          generateAndSendLink();
          setIsShowViberHelper(false);
        }}
        secondaryButtonText={t("secondaryButtonText")}
        secondaryButtonOnClick={() => setIsShowViberHelper(false)}
        onCloseButtonClick={() => setIsShowViberHelper(false)}
        title={t("generate-viber-title")}
        overflowY="visible"
        height="350px"
        content={
          <FormField
            label={t("label-phone-number")}
            status={
              phone(countryCode + number)?.isValid ? undefined : "warning"
            }
            statusMessage={
              phone(countryCode + number)?.isValid
                ? ""
                : t("invalid-status-msg")
            }
          >
            <Layout gap={6}>
              <Cell span={2}>
                <AutoComplete
                  popoverProps={{ placement: "bottom-start" }}
                  value={countryCode}
                  options={options}
                  onSelect={(option: any) => {
                    setCountryCode(option.id);
                  }}
                />
              </Cell>
              <Cell span={10}>
                <Input
                  type="number"
                  placeholder="12345678"
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                />
              </Cell>
              <Cell span={12}>
                <FormField label={t("label-default")}>
                  <InputArea
                    value={defaultMessage}
                    onChange={(e) => {
                      setDefaultMessage(e.target.value);
                    }}
                  />
                </FormField>
              </Cell>
            </Layout>
          </FormField>
        }
      />
    );
  };

  const handleGenerateLink = (link: any) => {
    setConfig({
      ...config,
      popup: {
        ...config.popup,
        persons: [
          ...config.popup.persons.slice(0, selectedPersonIndex),
          {
            ...config.popup.persons[selectedPersonIndex],
            link,
          },
          ...config.popup.persons.slice(selectedPersonIndex + 1),
        ],
      },
    });
  };
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={isShowSaved} autoHideTimeout={3000}>
        <Notification.TextLabel>
          {t("change-success-msg")}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Modal
        isOpen={isShowWaHelper}
        onRequestClose={() => setIsShowWaHelper(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <WaHelper onGenerateLink={handleGenerateLink} />
      </Modal>
      <Modal isOpen={showPostPublish} shouldCloseOnOverlayClick={true}>
        <AnnouncementModalLayout
          theme="premium"
          illustration={<Icons.Check size="200px" />}
          title={t("all-set-title")}
          primaryButtonText={t("upgrade-button")}
          primaryButtonOnClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                (instanceData as any)?.instance?.instanceId
              }`,
              "_blank"
            );
          }}
          linkText={t("link-button")}
          linkOnClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                (instanceData as any)?.instance?.instanceId
              }`,
              "_blank"
            );
          }}
          onCloseButtonClick={() => {
            setShowPostPublish(false);
          }}
        >
          <Box gap="SP2" direction="vertical">
            <Text>{t("upgrade-msg")}</Text>
            <SectionHelper
              title={"Don't see the widget?"}
              appearance="experimentalDark"
            >
              It can takes a few minutes to see the widget on live site. Widget
              does not show on preview / editor.
            </SectionHelper>
          </Box>
        </AnnouncementModalLayout>
      </Modal>
      <Modal
        isOpen={InteractiveDemoOpen}
        screen="desktop"
        shouldDisplayCloseButton={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setInteractiveDemoOpen(false)}
      >
        <CustomModalLayout
          removeContentPadding={true}
          content={
            <div
              style={{
                position: "relative",
                paddingBottom: "calc(56.22% + 41px)",
                height: 0,
                width: "100%",
              }}
            >
              <iframe
                src="https://demo.arcade.software/G3SlJXxbIvH2cBv1kz1Y?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                title="WhatsApp: Start Chat Button: Getting Started"
                frameBorder="0"
                loading="lazy"
                allow="clipboard-write"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  colorScheme: "light",
                }}
              ></iframe>
            </div>
          }
        />
      </Modal>
      <Modal
        isOpen={isShowTelegramHelper}
        onRequestClose={() => setIsShowTelegramHelper(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <TelegramHelper onGenerateLink={handleGenerateLink} />
      </Modal>
      <Modal
        isOpen={isShowMessengerHelper}
        onRequestClose={() => setIsShowMessengerHelper(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <MessengerHelper onGenerateLink={handleGenerateLink} />
      </Modal>
      <Modal
        isOpen={isShowViberHelper}
        onRequestClose={() => setIsShowViberHelper(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <ViberHelper onGenerateLink={handleGenerateLink} />
      </Modal>
      <Page height="100vh">
        <Page.Header
          title={t("whatsapp-title", { appName: APPS[appId]?.page?.name })}
          subtitle={t("whatsapp-subtitle", {
            appName: APPS[appId]?.page?.name,
          })}
          actionsBar={
            <Box gap="SP2">
              <Button
                skin="inverted"
                as="a"
                href={`https://bridget.reviews.certifiedcode.us/${appId}`}
                target="_blank"
              >
                {t("rate-button")}
              </Button>
              <Button
                prefixIcon={<Icons.Check />}
                onClick={() => {
                  setSettings();
                }}
              >
                {t("publish-change-button")}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            {JSON.stringify(config || "{}").includes("3475086396") && (
              <Cell span={12}>
                <SectionHelper
                  title={t("helper-title")}
                  actionText={t("helper-action-text")}
                  onAction={scrollToComponent}
                >
                  {t("helper-text")}
                </SectionHelper>
              </Cell>
            )}
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Box padding="4px" verticalAlign="middle">
                      <Box marginTop="3px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch Video
                        </Button>
                      </Box>
                    </Box>
                  }
                  title="Learn how to setup Start Chat Button"
                  description="Estimated time: 5 minutes"
                ></MarketingLayout>
              </Card>
            </Cell>
            <Cell span={9}>
              <Card>
                <Card.Header
                  title={t("showChatButtonTitle", {
                    appName: APPS[appId]?.page?.name,
                  })}
                  suffix={
                    <PulseAnimation
                      fluid
                      color="R10"
                      borderRadius={4}
                      active={!isEnabled}
                    >
                      <ToggleSwitch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                      />
                    </PulseAnimation>
                  }
                />
                <Cell span={12}>
                  <Card.Header title="Persons" />
                  <div ref={personList}></div>
                  <SelectableAccordion
                    items={config.popup.persons.map(
                      (person: any, index: any) => {
                        return {
                          title: person.text.title,
                          open: true,
                          content: (
                            <Layout>
                              <Cell span={12}>
                                <FormField
                                  label={t("avatar-image-label")}
                                  labelPlacement="left"
                                >
                                  <ImageViewer
                                    imageUrl={extractImageUrl(
                                      person.avatar.src
                                    )}
                                    onAddImage={() => {
                                      client.dashboard
                                        .openMediaManager({
                                          category: "IMAGE",
                                        })
                                        .then((chosenMediaItems: any) => {
                                          const url =
                                            chosenMediaItems?.items[0]?.url;
                                          if (url) {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    avatar: {
                                                      ...person.avatar,
                                                      src: `<img src="${url}" style="object-fit: cover; width: 100%; height: 100%;" alt="">`,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }
                                        });
                                    }}
                                    onUpdateImage={() => {
                                      client.dashboard
                                        .openMediaManager({
                                          category: "IMAGE",
                                        })
                                        .then((chosenMediaItems: any) => {
                                          const url =
                                            chosenMediaItems?.items[0]?.url;
                                          if (url) {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    avatar: {
                                                      ...person.avatar,
                                                      src: `<img src="${url}" style="object-fit: cover; width: 100%; height: 100%;" alt="">`,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }
                                        });
                                    }}
                                    showRemoveButton
                                    showUpdateButton
                                    width={100}
                                    height={100}
                                    showDownloadButton={false}
                                    onRemoveImage={() => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              avatar: {
                                                ...person.avatar,
                                                src: "",
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  />
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("avatar-background-color")}
                                  labelPlacement="left"
                                >
                                  <Box>
                                    <ColorInput
                                      value={person.avatar.backgroundColor}
                                      onChange={(e) => {
                                        setConfig({
                                          ...config,
                                          popup: {
                                            ...config.popup,
                                            persons: [
                                              ...config.popup.persons.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...person,
                                                avatar: {
                                                  ...person.avatar,
                                                  backgroundColor: e.toString(),
                                                },
                                              },
                                              ...config.popup.persons.slice(
                                                index + 1
                                              ),
                                            ],
                                          },
                                        });
                                      }}
                                    />
                                  </Box>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("avatar-online-circle")}
                                  labelPlacement="left"
                                >
                                  <Box>
                                    <ToggleSwitch
                                      checked={person.avatar.onlineCircle}
                                      onChange={() => {
                                        setConfig({
                                          ...config,
                                          popup: {
                                            ...config.popup,
                                            persons: [
                                              ...config.popup.persons.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...person,
                                                avatar: {
                                                  ...person.avatar,
                                                  onlineCircle:
                                                    !person.avatar.onlineCircle,
                                                },
                                              },
                                              ...config.popup.persons.slice(
                                                index + 1
                                              ),
                                            ],
                                          },
                                        });
                                      }}
                                    />
                                  </Box>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <Divider />
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("label-title")}
                                  labelPlacement="left"
                                >
                                  <Input
                                    value={person.text.title}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              text: {
                                                ...person.text,
                                                title: e.target.value,
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  ></Input>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("label-description")}
                                  labelPlacement="left"
                                >
                                  <Input
                                    value={person.text.description}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              text: {
                                                ...person.text,
                                                description: e.target.value,
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  ></Input>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("label-online")}
                                  labelPlacement="left"
                                >
                                  <Input
                                    suffix={
                                      !canUsePremium && (
                                        <Input.IconAffix>
                                          <PremiumHint />
                                        </Input.IconAffix>
                                      )
                                    }
                                    disableEditing={!canUsePremium}
                                    value={person.text.online}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              text: {
                                                ...person.text,
                                                online: e.target.value,
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  ></Input>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("label-offline")}
                                  labelPlacement="left"
                                >
                                  <Input
                                    suffix={
                                      !canUsePremium && (
                                        <Input.IconAffix>
                                          <PremiumHint />
                                        </Input.IconAffix>
                                      )
                                    }
                                    disableEditing={!canUsePremium}
                                    value={person.text.offline}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              text: {
                                                ...person.text,
                                                offline: e.target.value,
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  ></Input>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={"Button Label"}
                                  labelPlacement="left"
                                >
                                  <Input
                                    suffix={
                                      !canUsePremium && (
                                        <Input.IconAffix>
                                          <PremiumHint />
                                        </Input.IconAffix>
                                      )
                                    }
                                    disableEditing={!canUsePremium}
                                    value={person.text.button}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: [
                                            ...config.popup.persons.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...person,
                                              text: {
                                                ...person.text,
                                                button: e.target.value,
                                              },
                                            },
                                            ...config.popup.persons.slice(
                                              index + 1
                                            ),
                                          ],
                                        },
                                      });
                                    }}
                                  ></Input>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("label-desktop")}
                                  suffix={
                                    (appId ===
                                      "463fa61d-e677-4847-8749-b708786d1176" ||
                                      appId ===
                                        "4a40e74c-42a0-4a89-8a00-71eca92ea200" ||
                                      appId ===
                                        "c4aed3c4-7d9c-4a20-90a4-61515043f9f8" ||
                                      appId ===
                                        "08c29bbe-527f-48ed-87bc-1fe8ffb43cca" ||
                                      appId ===
                                        "764f46b2-7566-4133-9fcd-092b28fd970d" ||
                                      appId ===
                                        "797cdc28-6218-4a81-b1f0-f5ff20e311d2" ||
                                      appId ===
                                        "d6efa836-c8d5-4001-9598-8ca79f6abf27") && (
                                      <TextButton
                                        size="small"
                                        prefixIcon={<Icons.AIFilledSmall />}
                                        onClick={() => {
                                          switch (appId) {
                                            case "463fa61d-e677-4847-8749-b708786d1176":
                                              setIsShowWaHelper(true);
                                              break;
                                            case "4a40e74c-42a0-4a89-8a00-71eca92ea200":
                                              setIsShowTelegramHelper(true);
                                              break;
                                            case "c4aed3c4-7d9c-4a20-90a4-61515043f9f8":
                                              setIsShowMessengerHelper(true);
                                              break;
                                            case "08c29bbe-527f-48ed-87bc-1fe8ffb43cca":
                                              setIsShowViberHelper(true);
                                              break;
                                            case "764f46b2-7566-4133-9fcd-092b28fd970d":
                                              setIsShowWaHelper(true);
                                              break;
                                            case "797cdc28-6218-4a81-b1f0-f5ff20e311d2":
                                              setIsShowWaHelper(true);
                                              break;
                                            case "d6efa836-c8d5-4001-9598-8ca79f6abf27":
                                              setIsShowWaHelper(true);
                                              break;
                                          }
                                          setSelectedPersonIndex(index);
                                        }}
                                      >
                                        {`Generate ${APPS[appId]?.page?.name} Link`}
                                      </TextButton>
                                    )
                                  }
                                  status={
                                    person.link.desktop.includes("13475086396")
                                      ? "warning"
                                      : undefined
                                  }
                                  statusMessage={
                                    person.link.desktop.includes("13475086396")
                                      ? "Please replace the sample phone number with your own"
                                      : undefined
                                  }
                                >
                                  <Input
                                    value={person.link.desktop}
                                    onChange={(e) => {
                                      const updatedPersons = [
                                        ...config.popup.persons,
                                      ];
                                      updatedPersons[index] = {
                                        ...person,
                                        link: {
                                          ...person.link,
                                          desktop: e.target.value,
                                        },
                                      };
                                      setConfig({
                                        ...config,
                                        popup: {
                                          ...config.popup,
                                          persons: updatedPersons,
                                        },
                                      });
                                    }}
                                  />
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <FormField
                                  label={t("mobile-link")}
                                  status={
                                    person.link.mobile.includes("13475086396")
                                      ? "error"
                                      : undefined
                                  }
                                  statusMessage={
                                    person.link.mobile.includes("13475086396")
                                      ? "Please replace the sample phone number with your own"
                                      : undefined
                                  }
                                >
                                  <PulseAnimation
                                    fluid
                                    active={showPulseOnLink}
                                    color="B10"
                                    borderRadius={4}
                                  >
                                    <Input
                                      value={person.link.mobile}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setConfig({
                                          ...config,
                                          popup: {
                                            ...config.popup,
                                            persons: [
                                              ...config.popup.persons.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...person,
                                                link: {
                                                  ...person.link,
                                                  mobile: e.target.value,
                                                },
                                              },
                                              ...config.popup.persons.slice(
                                                index + 1
                                              ),
                                            ],
                                          },
                                        });
                                      }}
                                    ></Input>
                                  </PulseAnimation>
                                </FormField>
                              </Cell>
                              <Cell span={12}>
                                <Divider />
                              </Cell>
                              <Cell span={12}>
                                {/* Old One */}
                                {/* <FormField label={t('online-day')}>
                                      {Object.keys(person.onlineDay).map(
                                        (day: any) => (
                                          <Box>
                                            <ToggleSwitch
                                              // @ts-ignore
                                              checked={
                                                person.onlineDay[day] !== false
                                              }
                                              onChange={(event: any) => {
                                                const isToggleOn =
                                                  event.target.checked;
                                                setConfig({
                                                  ...config,
                                                  popup: {
                                                    ...config.popup,
                                                    persons: [
                                                      ...config.popup.persons.slice(
                                                        0,
                                                        index
                                                      ),
                                                      {
                                                        ...person,
                                                        onlineDay: {
                                                          ...person.onlineDay,
                                                          [day]: isToggleOn
                                                            ? "00:00-23:59"
                                                            : false,
                                                        },
                                                      },
                                                      ...config.popup.persons.slice(
                                                        index + 1
                                                      ),
                                                    ],
                                                  },
                                                });
                                              }}
                                            />
                                            <Text>{day}</Text>
                                            {
                                              // @ts-ignore
                                              person.onlineDay[day] && (
                                                <Box>
                                                  <FormField
                                                    label={t('online-time')}
                                                    labelPlacement="left"
                                                  >
                                                    <Box>
                                                    
                                                      <TimeInput
                                                        disabled={
                                                          !canUsePremium
                                                        }
                                                        suffix={
                                                          !canUsePremium && (
                                                            <Input.IconAffix>
                                                              <PremiumHint />
                                                            </Input.IconAffix>
                                                          )
                                                        }
                                                        invalidMessage={t('invalid-time-msg')}
                                                        value={
                                                          new Date(
                                                            new Date().toDateString() +
                                                              " " +
                                                              person.onlineDay[
                                                                day
                                                              ].split("-")[0]
                                                          )
                                                        }
                                                        onChange={({
                                                          date,
                                                        }: any) => {
                                                          setConfig({
                                                            ...config,
                                                            popup: {
                                                              ...config.popup,
                                                              persons: [
                                                                ...config.popup.persons.slice(
                                                                  0,
                                                                  index
                                                                ),
                                                                {
                                                                  ...person,
                                                                  onlineDay: {
                                                                    ...person.onlineDay,
                                                                    [day]:
                                                                      `${date.getHours()}:${date.getMinutes()}` +
                                                                      "-" +
                                                                      person.onlineDay[
                                                                        day
                                                                      ].split(
                                                                        "-"
                                                                      )[1],
                                                                  },
                                                                },
                                                                ...config.popup.persons.slice(
                                                                  index + 1
                                                                ),
                                                              ],
                                                            },
                                                          });
                                                        }}
                                                      />
                                                      {"to"}
                                                      <TimeInput
                                                        disabled={
                                                          !canUsePremium
                                                        }
                                                        suffix={
                                                          !canUsePremium && (
                                                            <Input.IconAffix>
                                                              <PremiumHint />
                                                            </Input.IconAffix>
                                                          )
                                                        }
                                                        invalidMessage={t('invalid-time-msg')}
                                                        value={
                                                          new Date(
                                                            new Date().toDateString() +
                                                              " " +
                                                              person.onlineDay[
                                                                day
                                                              ].split("-")[1]
                                                          )
                                                        }
                                                        onChange={({
                                                          date,
                                                        }: any) => {
                                                          setConfig({
                                                            ...config,
                                                            popup: {
                                                              ...config.popup,
                                                              persons: [
                                                                ...config.popup.persons.slice(
                                                                  0,
                                                                  index
                                                                ),
                                                                {
                                                                  ...person,
                                                                  onlineDay: {
                                                                    ...person.onlineDay,
                                                                    [day]:
                                                                      person.onlineDay[
                                                                        day
                                                                      ].split(
                                                                        "-"
                                                                      )[0] +
                                                                      "-" +
                                                                      `${date.getHours()}:${date.getMinutes()}`,
                                                                  },
                                                                },
                                                                ...config.popup.persons.slice(
                                                                  index + 1
                                                                ),
                                                              ],
                                                            },
                                                          });
                                                        }}
                                                      />
                                                    </Box>
                                                  </FormField>
                                                </Box>
                                              )
                                             
                                               
                                              
                                              
                                            }
                                          </Box>
                                        )
                                      )}
                                    </FormField> */}
                                {/* Old One */}
                                {/* .........................New One With checks of {typeof person.onlineDay[day] === "string"} .......................... */}
                                <FormField label={t("online-day")}>
                                  {Object.keys(person.onlineDay).map(
                                    (day: any) => (
                                      <Box key={day}>
                                        <ToggleSwitch
                                          checked={
                                            person.onlineDay[day] !== false
                                          }
                                          onChange={(event: any) => {
                                            const isToggleOn =
                                              event.target.checked;
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    onlineDay: {
                                                      ...person.onlineDay,
                                                      [day]: isToggleOn
                                                        ? "00:00-23:59"
                                                        : false,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }}
                                        />
                                        <Text>
                                          {day.charAt(0).toUpperCase() +
                                            day.slice(1)}
                                        </Text>
                                        {person.onlineDay[day] &&
                                        typeof person.onlineDay[day] ===
                                          "string" ? (
                                          <Box marginLeft={2}>
                                            <FormField
                                              label={t("online-time")}
                                              labelPlacement="left"
                                            >
                                              <Box>
                                                <TimeInput
                                                  disabled={!canUsePremium}
                                                  suffix={
                                                    !canUsePremium && (
                                                      <Input.IconAffix>
                                                        <PremiumHint />
                                                      </Input.IconAffix>
                                                    )
                                                  }
                                                  invalidMessage={t(
                                                    "invalid-time-msg"
                                                  )}
                                                  value={
                                                    new Date(
                                                      new Date().toDateString() +
                                                        " " +
                                                        person.onlineDay[
                                                          day
                                                        ].split("-")[0]
                                                    )
                                                  }
                                                  onChange={({ date }: any) => {
                                                    setConfig({
                                                      ...config,
                                                      popup: {
                                                        ...config.popup,
                                                        persons: [
                                                          ...config.popup.persons.slice(
                                                            0,
                                                            index
                                                          ),
                                                          {
                                                            ...person,
                                                            onlineDay: {
                                                              ...person.onlineDay,
                                                              [day]:
                                                                `${date.getHours()}:${date.getMinutes()}` +
                                                                "-" +
                                                                person.onlineDay[
                                                                  day
                                                                ].split("-")[1],
                                                            },
                                                          },
                                                          ...config.popup.persons.slice(
                                                            index + 1
                                                          ),
                                                        ],
                                                      },
                                                    });
                                                  }}
                                                />
                                                {" to "}
                                                <TimeInput
                                                  disabled={!canUsePremium}
                                                  suffix={
                                                    !canUsePremium && (
                                                      <Input.IconAffix>
                                                        <PremiumHint />
                                                      </Input.IconAffix>
                                                    )
                                                  }
                                                  invalidMessage={t(
                                                    "invalid-time-msg"
                                                  )}
                                                  value={
                                                    new Date(
                                                      new Date().toDateString() +
                                                        " " +
                                                        person.onlineDay[
                                                          day
                                                        ].split("-")[1]
                                                    )
                                                  }
                                                  onChange={({ date }: any) => {
                                                    setConfig({
                                                      ...config,
                                                      popup: {
                                                        ...config.popup,
                                                        persons: [
                                                          ...config.popup.persons.slice(
                                                            0,
                                                            index
                                                          ),
                                                          {
                                                            ...person,
                                                            onlineDay: {
                                                              ...person.onlineDay,
                                                              [day]:
                                                                person.onlineDay[
                                                                  day
                                                                ].split(
                                                                  "-"
                                                                )[0] +
                                                                "-" +
                                                                `${date.getHours()}:${date.getMinutes()}`,
                                                            },
                                                          },
                                                          ...config.popup.persons.slice(
                                                            index + 1
                                                          ),
                                                        ],
                                                      },
                                                    });
                                                  }}
                                                />
                                              </Box>
                                            </FormField>
                                          </Box>
                                        ) : (
                                          <Text></Text>
                                        )}
                                      </Box>
                                    )
                                  )}
                                </FormField>
                                {/* .........................New One With checks........................... */}
                              </Cell>
                              <Cell span={12}>
                                <Divider />
                              </Cell>
                              <Cell span={12}>
                                <Button
                                  skin="destructive"
                                  onClick={() => {
                                    setConfig({
                                      ...config,
                                      popup: {
                                        ...config.popup,
                                        persons: config.popup.persons.filter(
                                          (person: any, i: any) => i !== index
                                        ),
                                      },
                                    });
                                  }}
                                >
                                  {t("remove-person-button")}
                                </Button>
                              </Cell>
                            </Layout>
                          ),
                        };
                      }
                    )}
                  />
                  <Card.Content>
                    <Button
                      prefixIcon={<Icons.Add />}
                      onClick={() => {
                        // copy the last
                        const lastPerson =
                          config.popup.persons[config.popup.persons.length - 1];
                        setConfig({
                          ...config,
                          popup: {
                            ...config.popup,
                            persons: [...config.popup.persons, SAMPLE_PERSON],
                          },
                        });
                      }}
                    >
                      {t("add-person-button")}
                    </Button>
                  </Card.Content>
                </Cell>
                <Card.Header title="Design" />
                <Card.Content>
                  <Layout>
                    <Cell span={12}>
                      <FormField
                        label={t("button-position-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <SegmentedToggle
                            onClick={(_: unknown, value: any) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  position: value,
                                },
                              });
                            }}
                            selected={
                              config.button.position === "left"
                                ? "left"
                                : "right"
                            }
                          >
                            <SegmentedToggle.Icon
                              value="left"
                              tooltipText="Left"
                            >
                              <Icons.AlignLeft />
                            </SegmentedToggle.Icon>
                            <SegmentedToggle.Icon
                              value="right"
                              tooltipText="Right"
                            >
                              <Icons.AlignRight />
                            </SegmentedToggle.Icon>
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-style-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <SegmentedToggle
                            selected={String(config.button.style)}
                            size="medium"
                            onClick={(_: unknown, value: any) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  style: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 7 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-background-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <ColorInput
                            value={config.button.backgroundColor}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  backgroundColor: e.toString(),
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-effect-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <SegmentedToggle
                            selected={String(config.button.effect)}
                            size="medium"
                            onClick={(_: unknown, value: any) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  effect: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 7 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-notification-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <Input
                            value={config.button.notificationNumber || ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  notificationNumber:
                                    e.target.value.length > 0
                                      ? e.target.value
                                      : false,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-speech-title")}
                        labelPlacement="left"
                      >
                        <Input
                          value={config.button.speechBubble || ""}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfig({
                              ...config,
                              button: {
                                ...config.button,
                                speechBubble:
                                  e.target.value.length > 0
                                    ? e.target.value
                                    : false,
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("button-pulse-title")}
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.button.pulseEffect}
                            onChange={() => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  pulseEffect: !config.button.pulseEffect,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField
                          label={t("button-title")}
                          labelPlacement="left"
                        >
                          <Input
                            value={config.button.text.title}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    title: e.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField
                          label={t("button-description")}
                          labelPlacement="left"
                        >
                          <Input
                            value={config.button.text.description || ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    description:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField
                          label={t("button-online")}
                          labelPlacement="left"
                        >
                          <Input
                            suffix={
                              !canUsePremium && (
                                <Input.IconAffix>
                                  <PremiumHint />
                                </Input.IconAffix>
                              )
                            }
                            disableEditing={!canUsePremium}
                            value={config.button.text.online || ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    online:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField
                          label={t("button-offline")}
                          labelPlacement="left"
                        >
                          <Input
                            suffix={
                              !canUsePremium && (
                                <Input.IconAffix>
                                  <PremiumHint />
                                </Input.IconAffix>
                              )
                            }
                            disableEditing={!canUsePremium}
                            value={config.button.text.offline || ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    offline:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    <Cell span={12}>
                      <Divider />
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("popup-auto-open")}
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.popup.automaticOpen}
                            onChange={() => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  automaticOpen: !config.popup.automaticOpen,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("popup-outside-close")}
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.popup.outsideClickClosePopup}
                            onChange={() => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  outsideClickClosePopup:
                                    !config.popup.outsideClickClosePopup,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t("popup-effect")}>
                        <Box>
                          <SegmentedToggle
                            selected={String(config.popup.effect)}
                            size="small"
                            onClick={(_: unknown, value: any) => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  effect: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 15 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t("popup-header-background")}>
                        <Box>
                          <ColorInput
                            value={config.popup.header.backgroundColor}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  header: {
                                    ...config.popup.header,
                                    backgroundColor: e.toString(),
                                  },
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("popup-header-title")}
                        labelPlacement="left"
                      >
                        <Input
                          value={config.popup.header.title}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfig({
                              ...config,
                              popup: {
                                ...config.popup,
                                header: {
                                  ...config.popup.header,
                                  title: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("popup-header-description")}
                        labelPlacement="left"
                      >
                        <Input
                          value={config.popup.header.description || ""}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfig({
                              ...config,
                              popup: {
                                ...config.popup,
                                header: {
                                  ...config.popup.header,
                                  description:
                                    e.target.value.length > 0
                                      ? e.target.value
                                      : false,
                                },
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>

                    <Cell span={12}>
                      <Divider />
                    </Cell>

                    <Cell span={12}>
                      <FormField label={t("label-sound")} labelPlacement="left">
                        <Box>
                          <ToggleSwitch
                            checked={config.sound}
                            onChange={() => {
                              setConfig({
                                ...config,
                                sound: !config.sound,
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <SectionHelper
                        title="When user visits your site, they will see:"
                        appearance="standard"
                        actionText="Remove"
                        onAction={() => {
                          // for upgraded users
                          if (canUsePremium) {
                            setConfig({
                              ...config,
                              changeBrowserTitle: "",
                            });
                          } else {
                            // for non-upgraded users, open upgrade url
                            window.open(
                              `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                                (instanceData as any)?.instance?.instanceId
                              }`
                            );
                          }
                        }}
                      >
                        <Box
                          display="flex"
                          // flexDirection="column"
                          marginTop="16px"
                          width="300px"
                        >
                          <Box
                            display="flex"
                            // alignItems="center"
                            backgroundColor="#e4e4e4"
                            borderTopLeftRadius="6px"
                            borderTopRightRadius="6px"
                            height="32px"
                            padding="0 8px"
                          >
                            {/* Tab Controls */}
                            <Box display="flex" gap="6px" 
                            // alignItems="center"
                            >
                              <Box
                                width="10px"
                                height="10px"
                                borderRadius="50%"
                                backgroundColor="#ff5f57"
                              />
                              <Box
                                width="10px"
                                height="10px"
                                borderRadius="50%"
                                backgroundColor="#febc2e"
                              />
                              <Box
                                width="10px"
                                height="10px"
                                borderRadius="50%"
                                backgroundColor="#28c840"
                              />
                            </Box>
                            {/* Active Tab */}
                            <Box
                              display="flex"
                              // alignItems="center"
                              gap="6px"
                              padding="6px 12px"
                              backgroundColor="#ffffff"
                              borderRadius="6px"
                              margin="0 0 0 12px"
                              height="24px"
                            >
                              <Box
                                width="12px"
                                height="12px"
                                borderRadius="50%"
                                backgroundColor="#ff0000"
                                animation="flash 1s infinite"
                              />
                              <Text weight="normal" size="small">
                                {config.changeBrowserTitle || "New Message!"}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                        <style>
                          {`
                            @keyframes flash {
                               0% { opacity: 1; }
                               50% { opacity: 0.5; }
                               100% { opacity: 1; }
                             }
                          `}
                        </style>
                      </SectionHelper>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("change-browser-title")}
                        labelPlacement="left"
                      >
                        <Input
                          suffix={
                            !canUsePremium && (
                              <Input.IconAffix>
                                <PremiumHint />
                              </Input.IconAffix>
                            )
                          }
                          disableEditing={!canUsePremium}
                          value={config.changeBrowserTitle || ""}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfig({
                              ...config,
                              changeBrowserTitle:
                                e.target.value.length > 0
                                  ? e.target.value
                                  : false,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label={t("label-cookie")}
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.cookie}
                            onChange={() => {
                              setConfig({
                                ...config,
                                cookie: !config.cookie,
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={3}>
              <Card>
                <Card.Content>
                  <Layout>
                    <Cell>
                      <Button
                        prefixIcon={<Icons.PremiumFilled />}
                        skin="premium"
                        as="a"
                        href={`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                          (instanceData as any)?.instance?.instanceId
                        }`}
                        target="_blank"
                      >
                        {(() => {
                          const freeTrialAvailable =
                            instanceData?.instance?.freeTrialAvailable;
                          const packageName =
                            instanceData?.instance?.billing?.packageName;

                          if (freeTrialAvailable && !packageName) {
                            return t("start-trial");
                          } else if (packageName) {
                            return t("manage-plan");
                          } else {
                            return t("upgrade-now");
                          }
                        })()}
                      </Button>
                    </Cell>
                    {!instanceData?.instance?.freeTrialAvailable &&
                      canUsePremium && (
                        <Cell span={12}>
                          <CircularProgressBar
                            label={
                              isUpgraded && !trialRemainingDate
                                ? t("premium-msg")
                                : trialRemainingDate <= 0
                                ? t("premium-trial")
                                : t("proTrialEnds", { trialRemainingDate })
                            }
                            skin={
                              isUpgraded
                                ? trialRemainingDate
                                  ? "premium"
                                  : "standard"
                                : "premium"
                            }
                            showProgressIndication
                            error={isUpgraded ? false : trialRemainingDate <= 0}
                            errorMessage={
                              trialRemainingDate <= 0
                                ? t("premium-end")
                                : undefined
                            }
                            errorLabel={
                              trialRemainingDate <= 0
                                ? t("trial-end")
                                : undefined
                            }
                            value={
                              isUpgraded && !trialRemainingDate
                                ? 100
                                : (trialRemainingDate / 14) * 100
                            }
                          />
                        </Cell>
                      )}
                  </Layout>
                </Card.Content>
              </Card>
              <Page.Section title={t("add-more-chat-button")} showDivider />
              <Layout>
                {Object.keys(APPS)
                  .filter(
                    (v: string) => v !== appId && APPS[v]?.isPrivate !== true
                  )
                  .map((appId: string) => (
                    <Cell span={12}>
                      <CardGalleryItem
                        aspectRatio={1}
                        title={APPS[appId]?.page?.name}
                        imagePlacement="side"
                        backgroundImageUrl={APPS[appId]?.icon}
                        primaryActionProps={{
                          label: "Install",
                          disabled: !!APPS[appId]?.isPrivate,
                          onClick: () => {
                            window.open(
                              `https://go.certifiedcode.global/get/${appId}/${
                                (instanceData as any)?.site?.siteId
                              }`
                            );
                          },
                        }}
                      />
                    </Cell>
                  ))}
              </Layout>
            </Cell>

            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Button
                      size="small"
                      priority="secondary"
                      suffixIcon={<Icons.WixForms />}
                      onClick={() => {
                        window.Intercom("startSurvey", 44499573);
                      }}
                    >
                      Share Feedback
                    </Button>
                  }
                  title="Looking for something else?"
                  description="Share your feedback with us"
                ></MarketingLayout>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header title={`See what site owners on Wix are saying about ${APPS[appId]?.page?.name}: Start Chat Button`} />
                <Card.Content>
                <TestimonialList appIds={[appId]} />                </Card.Content>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
